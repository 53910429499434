body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slider img {
  object-fit: -cover;
}

.summary-item {
  display: flex;
  align-items: center;
  border: 1px solid lightblue;
  padding: 5px;
  margin: 2px;
}

.summary-item > * {
  flex: 1;
}

.main-category {
  background-color: lightgray;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  color: rgb(231, 25, 25);
  font-weight: bold;
  min-width: 50px;
  display: inline-block;
  text-align: center;
}

.sub-category {
  background-color: lightgray;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  color: rgb(207, 29, 29);
  min-width: 50px;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}

span[role="img"] {
  font-size: large !important;
}

.ant-radio-inner {
  font-weight: bold;
  color: red;
}

.product-cart-item-modal .slide img {
  width: 500px !important;
  height: 500px;
  object-fit: contain;
}

.ql-editor {
  min-height: 200px;
}

.your-address p {
  margin: 0;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  width: 300px;
  display: block;
}

.order-item th,
.order-item td {
  padding: 5px;
}
